.swiperProjects {
  width: 100vw;
  height: 800px !important;
  background-color: transparent !important;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-cube {
  overflow: hidden !important;
}

.socials a {
  height: 20px !important;
}

.socials a:hover {
  margin-top: 0 !important;
}

.socials a img {
  object-fit: contain;
  height: 100% !important;
  width: 100% !important;
}

.swiper-button-prev {
  left: 6%;
  z-index: 30;
  background-image: url("../img/lab/prev.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.swiper-button-next {
  right: 6%;
  z-index: 30;
  background-image: url("../img/lab/next.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

.swiper-pagination-bullet {
  background-color: white !important;
}

@media only screen and (min-width: 1024px) {
  .swiperProjects {
    height: 600px !important;
  }

  .socials a:hover {
    margin-top: -2px !important;
  }
}
