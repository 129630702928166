@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap");
@font-face {
  font-family: "AstroSpace";
  src: local("AstroSpace"),
    url(./assets/fonts/AstroSpace.otf) format("opentype");
}

@font-face {
  font-family: "LatoFamily";
  src: local("LatoFamily-Regular"),
    url(./assets/fonts/Lato-family/Lato-Regular.ttf) format("opentype");
}
.bg-blue-500 {
  background-color: transparent !important; 
}
#root {
  --primary: #0a0f20;
  --secondary: #11141e;
  --accent: #718096;
  --background: #fff;
  --main: #0d0106;
  --header: #2d3748;
  background-color: var(--primary) !important;
}

body {
  margin: 0;
  font-family: "LatoFamily", sans-serif;
  /* font-family: "Lato", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 20px;
}

.h1Bold {
  font-family: "Lato", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Font */
.font-astro-space {
  font-family: "AstroSpace";
}

.container {
  max-width: 1920px;
  width: 100vw !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
}

.swiperInfluencer {
  width: 100vw;
}
.swiperInfluencer div.swiper-button-next,
.swiperInfluencer div.swiper-button-prev {
  top: -9% !important;
  margin: 0rem 5rem;
}

/* @media screen and (max-width: 992px) {
  .title {
    font-size: calc((2.7 - 1) * 1.2vw + 1rem) !important;
  }
} */


@-webkit-keyframes anim-glow {
	0% {
		box-shadow: 0 0 rgba(#61EF61, 1);
	}
	100% {
		box-shadow: 0 0 10px 8px transparent;
		border-width: 2px;
	}
}

@keyframes anim-glow {
	0% {
		box-shadow: 0 0 rgba(#61EF61, 1);
	}
	100% {
		box-shadow: 0 0 10px 8px transparent;
		border-width: 2px;
	}
}
.glowingEffect {
  animation: glow 1.5s linear infinite alternate;
  filter: blur(0px);
  z-index: -10;
  fill: #aaa;
  transition: .2s;
}